
  .ticket .bg-plane {
    background-position: center center;
    background-size: 150%;
    background-repeat: no-repeat;
  }
  
  .ticket .bg-destination {
    background-image: url("/public/ticket-bg.jpeg");
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 150, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .ticket .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .ticket .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }
  .ticket body {
    background: #d9414a;
    background: -webkit-linear-gradient(to right, #8c5a9b, #d9414a);
    background: linear-gradient(to right, #8c5a9b, #d9414a);
  }
  
  .ticket .bg-purple-400 {
    background-color: #7b4295;
  }
  
  .ticket .text-purple-400 {
    color: #7b4295;
  }