
.dayContainer {
    display: flex;
    flex-direction: column;
    margin-inline: 8px;
    justify-content: center;
    align-items: center;
}

.tripWeatherContainer {
    display: flex;
    margin-block: 8px;
    overflow-x: auto;
    width:100%;
    height:115px;
}

.weatherIcon{
        
}