.rightPaneContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    height: 100%;
    width: 100px;
    align-items: center;
    background-color:#ccc;
}

.iconConatiner{
    margin-block: 20px;
}

.icon{
    height: 50px;
}
.icon:hover{
    cursor: pointer;
}