.iconContainer{
    display: flex;
    flex-direction: column;
    margin: 8px;
    align-items: center;
}

.icon:hover{
    cursor: pointer;
}

.rowPreferencesContainer{
    display: flex;
    justify-content: space-evenly;
}

.preferencesContainer{
    margin-bottom: 12px;
}