body {
  position: relative;
	background: rgb(2,140,199);

background: -moz-radial-gradient(circle, rgba(2,140,199,1) 0%, rgba(2,95,153,1) 100%);
background: -webkit-radial-gradient(circle, rgba(2,140,199,1) 0%, rgba(2,95,153,1) 100%);
background: radial-gradient(circle, rgba(2,140,199,1) 0%, rgba(2,95,153,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#028cc7",endColorstr="#025f99",GradientType=1);
}
_::-webkit-full-page-media, _:future, :root body {
	  background: rgb(2,140,199) !important;
  }
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #F1F1F1;
  display: flex;
  justify-content: center;
}

.containers {
  display: flex;
  height: 100%;
}

h1 {
  align-self: center !important;
}

.custom-btn {
  margin-top: 10px !important;
}

.centered{
  align-items: center;
  justify-content: center;
}

.activity-density {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.activity-density-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: #999;
}

.activity-density-label {
  flex-basis: 50%;
  text-align: center;
}

.activity-density-slider {
  width: 80%;
  height: 4px;
  background-color: #ccc;
  border-radius: 2px;
  margin: 0.5rem 0;
  cursor: pointer;
}

.activity-density-slider .input-range__track {
  background-color: #007bff;
}

.activity-density-slider .input-range__track--active {
  background-color: #007bff;
}

.activity-density-label-container {
  display: none;
}

.activity-density-value-label {
  display: none;
}


.destinationLbl {
 margin-right: 4px;
}

.left_container {
  flex:1;
  background: url("LeftContainerBG.jpeg") no-repeat fixed ;
  background-size: cover;
}

.right_container {
  flex:2;
  display: flex;
  flex-direction: column;
}

li {
  float: left;
  color:black
}

li a {
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

.flexRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-block: 8px;
}

.flexCol {
  display: flex;
  flex-direction: column;
}


.cloudsWrapper:before,
.cloudsWrapper:after {
	position: absolute;
	content: "";
	display: block;
	height: 100%;
	min-width: 2560px;
	width: 100%;
	background-size: auto 100%;
	-webkit-animation: clouds linear infinite alternate;
	animation: clouds linear infinite alternate;
}

.clouds {
	position: fixed;
	width: 100%;
	bottom: 0;
}

.cloudsWrapper {
	position: absolute;
	overflow: hidden;
	top: -149px;
	height: 150px;
	width: 100%;
}
.cloudsWrapper:after {
	background: url("./Images/cloud1.png") bottom repeat-x;
	-webkit-animation-duration: 210s;
	animation-duration: 210s;
}
.cloudsWrapper:before {
	background: url("./Images/cloud2.png") bottom repeat-x;
	-webkit-animation-duration: 120s;
	animation-duration: 120s;
}

.mainbg {
	position: relative;
	box-sizing: border-box;
}
.plane-container {
  position: fixed;
  top:70px;
  bottom:0px;
  left:0px;
  right:0px;
  overflow:hidden;
  z-index: -1;
}
.airplane {
  position: fixed;
  bottom:-1000px;
  right:-1000px;
  transition: all 1000ms;
  max-width: 1000px;
  height: auto;
}

.rightConatiner {
 display: flex;
 width: 50%;
}

.rightPane {
  position: absolute;
  right: 0;
  height: 100%;
  width: 100px;
  background-color:#ccc;
}

.prefModal {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  background-color: #ccc;
  align-items: center;
  padding:20px
}

.airplane.moved {
  bottom:0px;
  right:0px;
}

.airplane.full {
  bottom:1000px;
  right:1000px;
}
.airplane.hidden {
  opacity: 0;
}
.airplane.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}
div.MuiStepper-root {
  padding:0px;
  padding-top:25px;
}
.hideOnDemand {
  transition: opacity 500ms;
}
.makeHideOnDemand .hideOnDemand{
  opacity: 0;
}
.general-overlay {
  opacity: 0;
  position: fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  transition: opacity 1500ms;
  background-color: #fff;
}
.general-overlay.show {
  opacity: 1;
}
.rdrDefinedRangesWrapper {
  display:none;
}
.place-main-pic {
  height: 230px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000;
  border-radius: 12px;
  padding: 12px;
}
.place-pic {
  height: 400px;
  width: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.mapboxgl-popup-content {
  border-radius: 12px;
  padding:4px;
}


.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border: 1px solid rgba(226, 226, 232, 1);
  border-radius: 4px;
  padding: 16px;
  max-width: 640px;
  width: 100%;
  margin: 15px auto;
}

.card__content {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 1 auto;
}

.card__text {
  font-size: 16px;
  line-height: 32px;
}

.card__text--light {
  color: rgba(105, 105, 114, 1);
}

.card__text--dark {
  color: rgba(22, 22, 22, 1);
}

.card__highlight {
  background-color: rgba(242, 235, 255, 1);
  color: rgba(116, 89, 172, 1);
  border-radius: 3px;
  padding: 3px 8px;
}

.card__highlight.card__highlight--secondary {
  background-color: rgba(246, 245, 249, 1);
  color: rgba(80, 80, 80, 1);
}

.card__select {
  all: unset;
  background-color: rgba(242, 235, 255, 1);
  color: rgba(116, 89, 172, 1);
  border-radius: 3px;
  padding: 3px 8px;
  /* cursor: pointer; */
}

.card__select:hover {
  opacity: 0.6;
}

.card__btn {
  all: unset;
  color: white;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  border-radius: 4px;
  background: rgba(116, 89, 172, 1);
  cursor: pointer;
}

.card__btn:hover {
  opacity: 0.7;
}

.card__btn:disabled {
  opacity: 0.4;
  cursor: default;
}

.card__btn.card__btn--secondary:disabled {
  color: rgba(56, 130, 117, 1);
  background-color: rgba(229, 248, 244, 1);
  opacity: 1;
}

.card__text.offer-currency {
  margin-left: auto;
  border-left: 1px dashed rgba(226, 226, 232, 1);
  margin-top: -16px;
  margin-bottom: -16px;
  padding: 16px 16px 16px 32px;
}
div.react-date-picker .react-date-picker__wrapper {
  border:none;
  padding:5px;
}
div.react-tel-input .form-control {
  height: 42px;
  width: 100%;
  border-radius:0.5rem;
}
div.react-tel-input .flag-dropdown {
  border-radius: 0.5rem 0 0 0.5rem;
}
div.react-tel-input .country-list {
  display:block
}
div.react-tel-input .country-list .country {
  display:block;
  width: 100%;
}

.type-overlay {
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./Images/regular-trip.png');
}
.type-overlay-2 {
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./Images/custom-trip.png');
}

.type-activity-1 {
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./Images/ski.png');
}
.type-activity-2 {
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./Images/beaching.png');
}
.type-activity-3 {
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./Images/urban.png');
}
.type-selector {
  background-size:cover;
  height:150px;
  background-position: center;
   transition: all 0.2s ease;

		overflow: hidden;

		transform: scale(1);
		-webkit-transform: scale(1);
}
.type-selector:hover {

  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
.destionation-suggestion .type-selector {
    flex:45%
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes stars {
	0% {
		-webkit-transform: rotate(-14deg);
		transform: rotate(-14deg);
	}
	100% {
		-webkit-transform: rotate(14deg);
		transform: rotate(14deg);
	}
}

@keyframes clouds {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(-1000px);
		transform: translateX(-1000px);
	}
}
@media only screen and (max-width: 1024px) {
  .rightConatiner {
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .airplane.moved {
    bottom: 200px;
    right: -200px;
    width: 500px;
  }
  .airplane.full {
    bottom:500px;
    right:700px;
  }
}